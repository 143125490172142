import React, { useState, useRef } from 'react';
import http from './services/httpService';

export default function AnswerForm({ id, setConversationData }) {
    const [answer, setAnswer] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const handleInputChange = (e) => setAnswer(e.target.value);
    const inputRef = useRef(null);

    if (inputRef.current) {
        inputRef.current.focus();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAnswer('')
        setIsLoading(true);
        // Add the answer to the conversation optimistically
        setConversationData(prevData => ({
            ...prevData,
            conversation: [...prevData.conversation, { role: 'user', content: answer }, { role: 'assistant', content: '...' }]
        }));

        try {
            const response = await http.post(`/conversation/${id}`, { answer });
            // Replace the last message (typing indicator) with the actual response
            inputRef.current.focus();
            if (response.data.screeningCompleted) {
                window.location.reload();
            }
            setConversationData(prevData => ({
                ...prevData,
                conversation: prevData.conversation.slice(0, -1).concat(response.data.conversation.slice(-1))
            }));
        } catch (error) {
            console.error("Failed to submit answer: ", error);
        } finally {
            setAnswer("");
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="mt-4 flex justify-end">
            <input
                ref={inputRef}
                type="text"
                value={answer}
                onChange={handleInputChange}
                className="border-2 border-gray-200 rounded-md p-2 flex-grow mr-2"
                disabled={isLoading}
                placeholder="Your answer..."
            />
            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" disabled={isLoading}>
                {isLoading ? 'Waiting...' : 'Send'}
            </button>
        </form>
    );
}

