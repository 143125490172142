export function TitleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
}

export function displayBadge(score, isLarge) {
    const base = "inline-flex items-center rounded-md font-medium ring-1 ring-inset";
    const size = isLarge ? 'px-4 py-3 text-md' : 'px-3 py-2 text-xs';
    let colors = score > 50 ?
        'bg-orange-50 text-orange-700 ring-orange-600/10' :
        'bg-red-50 text-red-700 ring-red-600/10';

    if (score > 75)
        colors = 'bg-green-50 text-green-700 ring-green-600/10';

    const className = base + ' ' + size + ' ' + colors;

    return (
        <span className={className}>{score}</span>
    );
};