import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import http from './services/httpService';
import AnswerForm from './AnswerForm';
import SummaryView from './SummaryView';
import { TitleCase } from './services/tools';
import ConversationView from './ConversationView';
import SummaryToggle from './SummaryToggle';

const fetchConversation = async (id) => {
    const { data } = await http.get(`/conversation/${id}`);
    return data;
}

export default function Conversation() {
    const { id } = useParams();
    const { data, isLoading, error } = useQuery(['conversation', id], () => fetchConversation(id));
    const [conversationData, setConversationData] = useState({});
    const [showSummary, setShowSummary] = useState(false);
    const endOfMessagesRef = useRef(null);

    useEffect(() => {
        if (data) {
            setConversationData(data);
            if (data.summary)
                setShowSummary(true)
        }
    }, [data]);

    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [conversationData]);

    if (isLoading) return 'Loading...';
    if (error) return 'An error has occurred: ' + error.message;

    return (
        <div className="p-4 sm:p-6">
            {conversationData.jobTitle ? <div>
                <h2 className="text-2xl font-bold mb-4">{TitleCase(conversationData?.jobTitle)}</h2>
                {conversationData.screeningCompleted ?
                    <div>
                        <SummaryToggle showSummary={showSummary} setShowSummary={setShowSummary} />
                        {showSummary ?
                            <SummaryView summary={conversationData.summary} />
                            : <ConversationView conversationData={conversationData} />}
                    </div>
                    :
                    <div>
                        <ConversationView conversationData={conversationData} />
                        <div ref={endOfMessagesRef} />
                        <AnswerForm id={id} setConversationData={setConversationData} />
                    </div>
                }
            </div>
                :
                <div>Loading</div>
            }
        </div>
    );
}
