import React from 'react';

function TypingIndicator() {
    const [dotCount, setDotCount] = React.useState(0);

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            setDotCount((prevCount) => (prevCount + 1) % 4);
        }, 500);

        return () => clearInterval(intervalId);
    }, []);

    const dots = '.'.repeat(dotCount);

    return <p className='typing-indicator w-8 h-5 text-center'>{dots}</p>;
}

export default TypingIndicator;
