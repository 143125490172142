
import TypingIndicator from './TypingIndicator';

export default function ConversationView({ conversationData }) {
    return (
        <div>
            {conversationData?.conversation.map((message, index) => (
                <div key={index} className={`mb-4 ${message.role === 'assistant' ? 'text-blue-600 text-left' : 'text-green-600 text-right'}`}>
                    <span className="font-bold">{message.role === 'assistant' ? 'ZippyRecruit' : 'You'}: </span>
                    <div className={`${message.role === 'assistant' ? 'bg-blue-200' : 'bg-green-200'} inline-block rounded-xl p-2`}>
                        {message.content === '...'
                            ? <TypingIndicator />
                            : <p>{message.content}</p>}
                    </div>
                </div>
            ))}
        </div>
    );
}
