import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import httpService from './services/httpService';
import { useQuery } from 'react-query';
import { TitleCase, displayBadge } from './services/tools';

const fetchConversations = async (id) => {
    const { data } = await httpService.get(`/conversations`);
    return data;
}

export default function ListConversations() {
    const { data, isLoading } = useQuery([], () => fetchConversations())
    const [conversations, setConversations] = useState([]);

    useEffect(() => {
        if (data) {
            setConversations(data);
        }
    }, [data]);

    if (isLoading) {
        return (<div>Loading...</div>)
    }

    return (
        <div className="divide-y divide-gray-100 mb-16">
            <div className="grid grid-cols-4 py-3.5 pl-4 pr-3 text-md font-semibold text-gray-900 sm:pl-3">
                <div className="col-span-3">Job Title</div>
                <div className="col-span-1 text-right">Suitability</div>
            </div>

            <div className="bg-white">
                {conversations.map((conversation, index) => (
                    <Link to={`/${conversation.id}`} key={conversation.id}>
                        <div className={`grid grid-cols-4 hover:bg-blue-50 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} ${conversation.screeningCompleted ? '' : 'text-gray-400'}`}>
                            <div className="overflow-hidden whitespace-nowrap truncate col-span-3 pl-4 py-2">
                                <span className="text-blue-800 font-bold">{TitleCase(conversation.jobTitle)}</span>
                            </div>
                            <div className="text-right py-1 font-medium col-span-1 pr-4">
                                <span>{conversation.screeningCompleted ? displayBadge(conversation.fitScore) : ''}</span>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );

}
