import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CreateConversationForm from './CreateConversationForm';
import Conversation from './Conversation';
import Header from './Header';
import ListConversations from './ListConversations';

// 1. Create a client
const queryClient = new QueryClient()

export default function App() {
  return (
    // 2. Provide the client to your App
    <QueryClientProvider client={queryClient}>
      <Router>
        <Header />
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-">
          <Routes>
            <Route path="/" element={<ListConversations />} />
            <Route path="/new" element={<CreateConversationForm />} />
            <Route path="/:id" element={<Conversation />} />
          </Routes>
        </div>
      </Router>
    </QueryClientProvider>
  );
}
