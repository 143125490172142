export default function SummaryToggle({ showSummary, setShowSummary }) {
    const activeMainClass = "text-gray-900 group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium bg-gray-50 hover:bg-indigo-50 focus:z-10";
    const inactiveMainClass = "text-gray-500 hover:text-gray-700 group relative min-w-0 flex-1 bg-gray-100 overflow-hidden py-4 px-4 text-center text-sm font-medium hover:bg-indigo-50 focus:z-10";

    const inactiveBottomClass = "bg-transparent absolute inset-x-0 bottom-0 h-0.5";
    const activeBottomClass = "bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5";

    return (
        <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow mb-6" aria-label="Tabs">
            <button onClick={() => setShowSummary(false)} className={showSummary ? inactiveMainClass : activeMainClass}>
                <span>Conversation</span>
                <span aria-hidden="true" className={showSummary ? inactiveBottomClass : activeBottomClass}></span>
            </button>
            <button onClick={() => setShowSummary(true)} className={showSummary ? activeMainClass : inactiveMainClass}>
                <span>Summary</span>
                <span aria-hidden="true" className={showSummary ? activeBottomClass : inactiveBottomClass}></span>
            </button>
        </nav >
    )
}
