import { displayBadge } from "./services/tools";

export default function SummaryView({ summary }) {
    return (
        <div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <h3 className="text-base text-xl font-semibold leading-7 text-gray-900">Suitability</h3>
                <p className="mt-1 max-w-2xl text-md leading-6 text-gray-500">{displayBadge(summary.fitScore, true)}</p>
            </div>
            <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-md font-medium leading-6 text-gray-900">Reason for suitability score</dt>
                        <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{summary.fitScoreReason}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-md font-medium leading-6 text-gray-900">Positives</dt>
                        <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <ul>
                                {summary.positives.map((positive, index) => <li className="list-none my-2" key={index}><span className="mr-1">✅</span> {positive}</li>)}
                            </ul>
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-md font-medium leading-6 text-gray-900">Negatives</dt>
                        <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <ul>
                                {summary.negatives.map((negative, index) => <li className="list-none my-2" key={index}><span className="mr-1">❌</span> {negative}</li>)}
                            </ul>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    );
}
